import {InstagramLogo} from '@phosphor-icons/react'
import Link from 'next/link'

export interface FooterProps {
    invert?: boolean
}

function Footer(props: FooterProps) {
    const invert = props.invert ?? false
    return (
        <footer
            className={`px-2 py-4 gap-y-4 md:gap-y-0 md:p-20 grid grid-cols-1 md:grid-cols-3 grid-rows-3 ${
                invert ? 'bg-black text-white' : 'bg-white text-black'
            }`}
        >
            <ul className="subhead-1 md:row-span-2 uppercase">
                <li>
                    <Link href="/projects">Work</Link>
                </li>
                <li>
                    <Link href="/about">About</Link>
                </li>
                <li>
                    <Link href="/studios">Studio</Link>
                </li>
                <li>
                    <Link href="/contact">Contact</Link>
                </li>
            </ul>
            <div className="flex flex-col justify-end md:row-start-3 text-crimson uppercase font-presicav col-span-2">
                TM & © 2023 ERMAZD. <br className="md:hidden" />
                ALL RIGHTS RESERVED. <br />A unit of Brahm Media Private Limited.
            </div>
            <ul className="flex flex-col justify-end items-end md:row-start-3 body-3">
                <li className={'uppercase mb-2'}>
                    <Link
                        href="https://instagram.com/ermazd"
                        target="_blank"
                        className={'flex items-center'}
                    >
                        <span className={'pt-0.5 mr-1'}>Ermazd</span>
                        <InstagramLogo size={24} />
                    </Link>
                </li>
                <li className={'uppercase'}>
                    <Link
                        href="https://instagram.com/ermazdstudio"
                        target="_blank"
                        className={'flex items-center'}
                    >
                        <span className={'pt-0.5 mr-1'}>Studio</span>
                        <InstagramLogo size={24} />
                    </Link>
                </li>
            </ul>
        </footer>
    )
}

export default Footer
