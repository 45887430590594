import classNames from 'classnames'
import classnames from 'classnames'
import {motion, Transition, Variants} from 'framer-motion'
import {LayoutGroup} from 'framer-motion'
import Image, {ImageProps, StaticImageData} from 'next/image'
import Link from 'next/link'
import {forwardRef, useEffect, useState} from 'react'

const NextImageComponent = forwardRef<HTMLImageElement, ImageProps>(
    function NextImageComponentWrapper(props, ref) {
        // eslint-disable-next-line jsx-a11y/alt-text
        return <Image {...props} ref={ref} />
    }
)

const MotionImage = motion(NextImageComponent)

export interface HeroProps {
    image?: string | StaticImageData
    inverted?: boolean
    animated?: boolean
    children?: React.ReactNode
    unlimitedHeight?: boolean
}

function Hero(props: HeroProps) {
    const inverted = props.inverted ?? false
    const unlimitedHeight = props.unlimitedHeight ?? false
    const animated = props.animated ?? false
    const [opened, setOpened] = useState(false)
    const [clicked, setClicked] = useState(false)
    const hasImageBg = props.image !== undefined
    const textColor = inverted ? 'text-white' : 'text-black'
    const fillColor = inverted ? 'fill-white' : 'fill-black'
    const headerClass = classNames(
        {'text-white': inverted},
        {'text-black': !inverted},
        {'bg-black': inverted && !hasImageBg},
        {'bg-white': !inverted && !hasImageBg},
        {'h-screen': !unlimitedHeight && hasImageBg},
        {'h-2/3-screen': !unlimitedHeight && !hasImageBg},
        {'w-full overflow-hidden': hasImageBg},
        'relative'
    )

    useEffect(() => {
        setClicked(true)
    })

    const bgVariants: Variants = {
        initial: {
            filter: 'grayscale(1)',
        },
        clicked: {
            filter: 'grayscale(0)',
        },
    }
    const logoVariants: Variants = {
        initial: {
            y: '80vh',
            scale: 1.5,
        },
        clicked: {
            y: 0,
            scale: 1,
        },
    }
    const desktopNavLiVariants: Variants = {
        initial: {
            opacity: 0,
        },
        clicked: {
            opacity: 1,
        },
    }
    const mobileNavVariants: Variants = {
        closed: {
            y: '-100vh',
        },
        opened: {
            y: 0,
        },
    }
    const mobileNavLiVariants: Variants = {
        closed: {
            opacity: 0,
            y: '-100vh',
        },
        opened: {
            opacity: 1,
            y: 0,
        },
    }
    const hamburgerVariants: Variants = {
        initial: {
            opacity: 0,
        },
        clicked: {
            opacity: 1,
        },
    }
    const transition: Transition = {
        ease: 'easeIn',
        duration: 1.2,
    }
    const navItems = [
        {
            title: 'Work',
            href: '/projects/',
        },
        {
            title: 'Studio',
            href: '/studios/',
        },
        {
            title: 'Gear',
            href: '/gear',
        },
        {
            title: 'About',
            href: '/about',
        },
        {
            title: 'Press',
            href: '/press',
        },
        {
            title: 'Contact',
            href: '/contact',
        },
    ]
    const desktopAnimation = animated ? (clicked ? 'clicked' : 'initial') : 'clicked'
    const mobileAnimation = opened ? 'opened' : 'closed'
    const desktopInitialVariant = animated ? 'initial' : 'clicked'
    const mobileInitialVariant = 'closed'
    const mobileNavLiClass = classnames('font-presicav text-[24px] text-white')
    const desktopNavLiClass = classnames('font-avenir text-[16px] grow', textColor)
    return (
        <LayoutGroup>
            <header
                className={headerClass}
                onClick={() => {
                    setClicked(true)
                }}
            >
                {props.image && (
                    <MotionImage
                        alt="Background Image"
                        src={props.image}
                        quality={100}
                        fill
                        sizes="100vw"
                        className="z-[-10]"
                        style={{
                            objectFit: 'cover',
                        }}
                        variants={bgVariants}
                        transition={transition}
                        animate={desktopAnimation}
                        initial={desktopInitialVariant}
                    />
                )}
                <nav id="desktop-nav" aria-hidden={true} className="hidden md:block">
                    <motion.ul className="justify-around relative flex flex-row items-center w-screen pt-20 uppercase list-none px-0 text-center">
                        <>
                            <div className="flex flex-row w-1/4 justify-around items-center">
                                {navItems.slice(0, 3).map((item) => (
                                    <motion.li
                                        className={desktopNavLiClass}
                                        variants={desktopNavLiVariants}
                                        initial={desktopInitialVariant}
                                        animate={desktopAnimation}
                                        key={item.href}
                                    >
                                        <Link href={item.href}>{item.title}</Link>
                                    </motion.li>
                                ))}
                            </div>
                            <li className="flex flex-row items-center justify-between -mx-4">
                                <Link href="/">
                                    <motion.svg
                                        width="180"
                                        height="22"
                                        viewBox="0 0 180 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={fillColor}
                                        variants={logoVariants}
                                        transition={transition}
                                        initial={desktopInitialVariant}
                                        animate={desktopAnimation}
                                    >
                                        <path d="M22.2954 3.4031H3.91604V8.71318H20.1921V12.1473H3.91604V17.7442H22.7008V21.4651H0V0H22.2954V3.4031Z" />
                                        <path d="M51.681 21.4496H46.2276L39.6116 13.2791H31.336V21.4496H27.4199V0H43.9178C49.157 0 51.7575 2.57364 51.7575 6.67442C51.7575 10.7752 48.9964 12.907 44.5755 13.2558L51.6963 21.4574L51.681 21.4496ZM31.3283 3.55814V9.62791H43.4053C46.1052 9.62791 47.6426 8.5814 47.6426 6.54264C47.6426 4.63566 46.2964 3.55814 43.8795 3.55814H31.336H31.3283Z" />
                                        <path d="M87.7124 21.4186H83.7275V2.95349L74.2893 21.4496H70.4956L61.4015 2.95349V21.4186H57.4473V0H64.4074L72.5607 16.2713L80.997 0H87.7048V21.4186H87.7124Z" />
                                        <path d="M119.286 21.4496H114.743L112.234 16.5581H99.1555L96.708 21.4496H92.4707L103.263 0H108.25L119.286 21.4496ZM100.662 13.2791H110.789L105.741 3.45737L100.662 13.2791Z" />
                                        <path d="M148.045 2.76744L129.796 17.7674H148.206V21.4574H123.088V18.3411L141.498 3.4031H123.501V0H148.053V2.76744H148.045Z" />
                                        <path d="M166.142 0C173.729 0 180.001 2.16279 180.001 10.7132C180.001 15.5116 176.704 21.4574 166.861 21.4574H154.164V0H166.142ZM158.08 3.33333V17.6744H166.356C173.033 17.6744 175.916 14.5581 175.916 10.5891C175.916 5.56589 172.276 3.34109 166.134 3.34109H158.072L158.08 3.33333Z" />
                                    </motion.svg>
                                </Link>
                            </li>
                            <div className="flex flex-row w-1/4 justify-around items-center">
                                {navItems.slice(3).map((item) => (
                                    <motion.li
                                        className={desktopNavLiClass}
                                        variants={desktopNavLiVariants}
                                        initial={desktopInitialVariant}
                                        animate={desktopAnimation}
                                        key={item.href}
                                    >
                                        <Link href={item.href}>{item.title}</Link>
                                    </motion.li>
                                ))}
                            </div>
                        </>
                    </motion.ul>
                </nav>
                <nav id="mobile-nav" className="md:hidden">
                    <div className="flex flex-row items-center justify-between px-8 pt-8">
                        <div className="w-6"></div>
                        <Link href="/" className="z-20">
                            <motion.svg
                                width="180"
                                height="22"
                                viewBox="0 0 180 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={fillColor}
                                variants={logoVariants}
                                transition={transition}
                                initial={desktopInitialVariant}
                                animate={desktopAnimation}
                            >
                                <path d="M22.2954 3.4031H3.91604V8.71318H20.1921V12.1473H3.91604V17.7442H22.7008V21.4651H0V0H22.2954V3.4031Z" />
                                <path d="M51.681 21.4496H46.2276L39.6116 13.2791H31.336V21.4496H27.4199V0H43.9178C49.157 0 51.7575 2.57364 51.7575 6.67442C51.7575 10.7752 48.9964 12.907 44.5755 13.2558L51.6963 21.4574L51.681 21.4496ZM31.3283 3.55814V9.62791H43.4053C46.1052 9.62791 47.6426 8.5814 47.6426 6.54264C47.6426 4.63566 46.2964 3.55814 43.8795 3.55814H31.336H31.3283Z" />
                                <path d="M87.7124 21.4186H83.7275V2.95349L74.2893 21.4496H70.4956L61.4015 2.95349V21.4186H57.4473V0H64.4074L72.5607 16.2713L80.997 0H87.7048V21.4186H87.7124Z" />
                                <path d="M119.286 21.4496H114.743L112.234 16.5581H99.1555L96.708 21.4496H92.4707L103.263 0H108.25L119.286 21.4496ZM100.662 13.2791H110.789L105.741 3.45737L100.662 13.2791Z" />
                                <path d="M148.045 2.76744L129.796 17.7674H148.206V21.4574H123.088V18.3411L141.498 3.4031H123.501V0H148.053V2.76744H148.045Z" />
                                <path d="M166.142 0C173.729 0 180.001 2.16279 180.001 10.7132C180.001 15.5116 176.704 21.4574 166.861 21.4574H154.164V0H166.142ZM158.08 3.33333V17.6744H166.356C173.033 17.6744 175.916 14.5581 175.916 10.5891C175.916 5.56589 172.276 3.34109 166.134 3.34109H158.072L158.08 3.33333Z" />
                            </motion.svg>
                        </Link>
                        <motion.div
                            className={classNames(`tham tham-e-squeeze tham-w-6 z-10`, {
                                'tham-active': opened,
                            })}
                            onClick={() => setOpened(!opened)}
                            variants={hamburgerVariants}
                            transition={transition}
                            initial={desktopInitialVariant}
                            animate={desktopAnimation}
                        >
                            <div className="tham-box">
                                <div
                                    className={classNames(
                                        'tham-inner',
                                        {'bg-white': inverted},
                                        {'bg-black': inverted}
                                    )}
                                />
                            </div>
                        </motion.div>
                    </div>
                    <motion.ul
                        className="fixed inset-0 justify-around flex flex-col items-stretch w-screen h-screen uppercase list-none px-8 pt-16 text-center bg-black"
                        variants={mobileNavVariants}
                        initial={mobileInitialVariant}
                        animate={mobileAnimation}
                    >
                        <>
                            {navItems.map((item) => (
                                <motion.li
                                    className={mobileNavLiClass}
                                    variants={mobileNavLiVariants}
                                    initial={mobileInitialVariant}
                                    animate={mobileAnimation}
                                    key={item.href}
                                >
                                    <Link href={item.href}>{item.title}</Link>
                                </motion.li>
                            ))}
                        </>
                    </motion.ul>
                </nav>
                {props.children && props.children}
            </header>
        </LayoutGroup>
    )
}
export default Hero
